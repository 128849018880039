import { __decorate, __extends } from "tslib";
import { inject, observer } from 'mobx-react';
import React from 'react';
import { BASE_DOCUMENT } from '../../api';
import { detail } from '../../api/customers';
import { deleteDocument, list, updateDocument } from '../../api/document';
import { Button } from '../../components/Button';
import SS from '../../components/CustomerItem.styl';
import { SpinnerBlock } from '../../components/Spinner';
import { FileIcon } from '../../icons/FileIcon';
import { DocumentStatus } from '../../models/Document';
import { customConfirm } from '../../utils/utils';
import S from './customers.styl';
var CustomersDetailView = /** @class */ (function (_super) {
    __extends(CustomersDetailView, _super);
    function CustomersDetailView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            loading: false,
            showCreateModal: false,
        };
        _this.onUpdate = function () {
            _this.fetchData();
        };
        _this.openDocument = function (document) {
            var url = BASE_DOCUMENT + document.fileName;
            window.open(url, '_blank');
        };
        _this.getDocumentStatus = function (document) {
            var i18n = _this.props.store.i18n;
            if (document.expiryDate) {
                return i18n.documentsStatusExpired;
            }
            else {
                return i18n.documentsStatusItem(document.status || DocumentStatus.pending);
            }
        };
        _this.updateDocument = function (customerId, documentId, status) {
            var i18n = _this.props.store.i18n;
            var title = status === DocumentStatus.approved ? i18n.documentsApproveInfo : i18n.documentsRejectInfo;
            customConfirm(title, _this.props.store, function (success) {
                if (success) {
                    _this.setState({ loading: true });
                    var request = { customerId: customerId, documentId: documentId, status: status };
                    updateDocument(request).then(function () {
                        _this.setState({ loading: false });
                        _this.fetchData();
                    }).catch(function (error) {
                        _this.setState({ loading: false });
                        alert(error);
                    });
                }
            });
        };
        _this.deleteDocument = function (documentId) {
            var i18n = _this.props.store.i18n;
            customConfirm(i18n.alertDeleteDocumentSummary, _this.props.store, function (success) {
                if (success) {
                    _this.setState({ loading: true });
                    var request = { documentId: documentId };
                    deleteDocument(request).then(function () {
                        _this.setState({ loading: false });
                        _this.fetchData();
                    }).catch(function (error) {
                        _this.setState({ loading: false });
                        alert(error);
                    });
                }
            });
        };
        return _this;
    }
    CustomersDetailView.prototype.componentDidMount = function () {
        var store = this.props.store;
        if (store) {
            this.fetchData();
        }
    };
    CustomersDetailView.prototype.fetchData = function () {
        var _this = this;
        this.setState({ loading: true });
        detail(this.props.customerId || 0).then(function (result) {
            _this.setState({
                loading: false,
                customer: result,
            });
        }).catch(function (error) {
            alert(error.toString());
            throw error;
        });
    };
    CustomersDetailView.prototype.fetchDocuments = function () {
        var _this = this;
        this.setState({ loading: true });
        list({ type: 'document' }).then(function (result) {
            _this.setState({
                loading: false,
                documents: result.items,
            });
        }).catch(function (error) {
            alert(error.toString());
            throw error;
        });
    };
    CustomersDetailView.prototype.render = function () {
        var _this = this;
        var _a, _b;
        var props = this.props;
        var i18n = props.store.i18n;
        var _c = this.state, loading = _c.loading, customer = _c.customer;
        return (React.createElement("div", { className: S.customersView },
            React.createElement("h1", { className: S.customersTitle }, i18n.customersTitle),
            React.createElement("hr", { className: S.customersSeparator }),
            React.createElement("div", { className: S.customersMainBox }, loading ?
                React.createElement(SpinnerBlock, null)
                :
                    React.createElement(React.Fragment, null,
                        React.createElement("div", { className: S.customersList },
                            React.createElement("div", { className: S.card },
                                React.createElement("div", { className: S.profileContainer }, !(customer === null || customer === void 0 ? void 0 : customer.photo) || customer.photo === '' ?
                                    React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", enableBackground: "new 0 0 24 24", height: "120px", viewBox: "0 0 24 24", width: "120px", fill: "#e8eaed" },
                                        React.createElement("g", null,
                                            React.createElement("rect", { fill: "none", height: "24", width: "24" })),
                                        React.createElement("g", null,
                                            React.createElement("path", { d: "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 4c1.93 0 3.5 1.57 3.5 3.5S13.93 13 12 13s-3.5-1.57-3.5-3.5S10.07 6 12 6zm0 14c-2.03 0-4.43-.82-6.14-2.88C7.55 15.8 9.68 15 12 15s4.45.8 6.14 2.12C16.43 19.18 14.03 20 12 20z" })))
                                    :
                                        React.createElement("img", { src: customer.photo, alt: customer.photo })),
                                React.createElement("div", { className: S.profileInfo },
                                    React.createElement("h1", null, customer === null || customer === void 0 ? void 0 :
                                        customer.firstName,
                                        " ", customer === null || customer === void 0 ? void 0 :
                                        customer.lastName),
                                    React.createElement("p", { className: S.jobTitle }, customer === null || customer === void 0 ? void 0 : customer.companyName),
                                    React.createElement("p", { className: S.desc }, customer === null || customer === void 0 ? void 0 : customer.email),
                                    React.createElement("p", { className: S.desc }, customer === null || customer === void 0 ? void 0 : customer.phone)),
                                React.createElement("div", { className: S.cardBottom })),
                            ((_a = customer === null || customer === void 0 ? void 0 : customer.documents) === null || _a === void 0 ? void 0 : _a.length) !== 0 ?
                                React.createElement("div", null,
                                    React.createElement("span", { className: SS.subTitle }, i18n.documentsTitle),
                                    React.createElement("div", { className: SS.documentList }, (_b = customer === null || customer === void 0 ? void 0 : customer.documents) === null || _b === void 0 ? void 0 : _b.map(function (document, i) { return (React.createElement("div", { className: SS.documentItem, key: i },
                                        React.createElement(FileIcon, { width: 48, height: 48 }),
                                        React.createElement("div", { onClick: function (_) { return _this.openDocument(document); }, className: SS.documentName },
                                            i18n.customersName,
                                            ": ",
                                            document.name,
                                            React.createElement("br", null),
                                            i18n.customersStatus,
                                            ": (",
                                            _this.getDocumentStatus(document),
                                            ")"),
                                        React.createElement("div", { className: SS.action }, document.status === DocumentStatus.pending ?
                                            React.createElement(React.Fragment, null,
                                                React.createElement(Button, { className: SS.approveButton, type: "button", onClick: function (_) { return _this.updateDocument(customer === null || customer === void 0 ? void 0 : customer.id, document.id, DocumentStatus.approved); } }, i18n.actionApprove),
                                                React.createElement(Button, { className: SS.rejectButton, type: "button", onClick: function (_) { return _this.updateDocument(customer === null || customer === void 0 ? void 0 : customer.id, document.id, DocumentStatus.rejected); } }, i18n.actionReject))
                                            :
                                                React.createElement(React.Fragment, null,
                                                    React.createElement(Button, { className: SS.deleteButton, type: "button", onClick: function (_) { return _this.deleteDocument(document.id); } }, i18n.actionDelete))))); })))
                                :
                                    React.createElement(React.Fragment, null))))));
    };
    CustomersDetailView = __decorate([
        inject('store'),
        observer
    ], CustomersDetailView);
    return CustomersDetailView;
}(React.Component));
export { CustomersDetailView };
