import { __decorate, __extends } from "tslib";
import { inject, observer } from 'mobx-react';
import React from 'react';
import { list as customerList } from '../../api/customers';
import SS from '../../auth/auth.styl';
import { Button } from '../../components/Button';
import { CustomerItem } from '../../components/CustomerItem';
import { Dropdown } from '../../components/Dropdown';
import { SpinnerBlock } from '../../components/Spinner';
import { CustomerStatus } from '../../models/Customer';
import { AddCustomerModal } from './AddCustomerModal';
import S from './customers.styl';
var CustomersView = /** @class */ (function (_super) {
    __extends(CustomersView, _super);
    function CustomersView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            customers: [],
            loading: false,
            showCreateModal: false,
        };
        _this.onUpdate = function () {
            _this.fetchData();
        };
        _this.createAction = function () {
            _this.setState({
                showCreateModal: true,
            });
        };
        _this.onCloseAdd = function () {
            _this.setState({
                showCreateModal: false,
            });
        };
        _this.isUpdatedAdd = function () {
            _this.setState({
                showCreateModal: false,
            });
            _this.fetchData();
        };
        _this.onChangeAction = function (e) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            var name = e.currentTarget.name;
            // if (e.currentTarget.value?.length < 3) { return; }
            if (name === 'firstName') {
                _this.setState({
                    filters: {
                        firstName: e.currentTarget.value,
                        lastName: (_a = _this.state.filters) === null || _a === void 0 ? void 0 : _a.lastName,
                        email: (_b = _this.state.filters) === null || _b === void 0 ? void 0 : _b.email,
                        status: (_c = _this.state.filters) === null || _c === void 0 ? void 0 : _c.status,
                    },
                }, function () {
                    _this.fetchData();
                });
            }
            if (name === 'lastName') {
                _this.setState({
                    filters: {
                        firstName: (_d = _this.state.filters) === null || _d === void 0 ? void 0 : _d.firstName,
                        lastName: e.currentTarget.value,
                        email: (_e = _this.state.filters) === null || _e === void 0 ? void 0 : _e.email,
                        status: (_f = _this.state.filters) === null || _f === void 0 ? void 0 : _f.status,
                    },
                }, function () {
                    _this.fetchData();
                });
            }
            if (name === 'email') {
                _this.setState({
                    filters: {
                        firstName: (_g = _this.state.filters) === null || _g === void 0 ? void 0 : _g.firstName,
                        lastName: (_h = _this.state.filters) === null || _h === void 0 ? void 0 : _h.lastName,
                        email: e.currentTarget.value,
                        status: (_j = _this.state.filters) === null || _j === void 0 ? void 0 : _j.status,
                    },
                }, function () {
                    _this.fetchData();
                });
            }
        };
        _this.onChangeDropdown = function (status) {
            var _a, _b, _c;
            _this.setState({
                filters: {
                    firstName: (_a = _this.state.filters) === null || _a === void 0 ? void 0 : _a.firstName,
                    lastName: (_b = _this.state.filters) === null || _b === void 0 ? void 0 : _b.lastName,
                    email: (_c = _this.state.filters) === null || _c === void 0 ? void 0 : _c.email,
                    status: status,
                },
            }, function () {
                _this.fetchData();
            });
        };
        return _this;
    }
    CustomersView.prototype.componentDidMount = function () {
        var store = this.props.store;
        if (store) {
            this.fetchData();
        }
    };
    CustomersView.prototype.fetchData = function () {
        var _this = this;
        this.setState({ loading: true });
        customerList(true, this.state.filters).then(function (result) {
            _this.setState({
                loading: false,
                customers: result.items,
            });
        }).catch(function (error) {
            alert(error.toString());
            throw error;
        });
    };
    CustomersView.prototype.render = function () {
        var _this = this;
        var props = this.props;
        var i18n = props.store.i18n;
        var _a = this.state, loading = _a.loading, customers = _a.customers, filters = _a.filters;
        return (React.createElement("div", { className: S.customersView },
            React.createElement("h1", { className: S.customersTitle }, i18n.customersTitle),
            React.createElement("hr", { className: S.customersSeparator }),
            React.createElement("div", { className: S.customersHeaderActions },
                React.createElement(Button, { className: "primaryButton " + S.addButton, type: "button", onClick: this.createAction }, i18n.actionCreate)),
            React.createElement("div", { className: S.customersFilters },
                React.createElement("div", { className: S.customersFiltersTitle }, i18n.customersFilters),
                React.createElement("div", { className: SS.authInputWrapper + " " + SS.small },
                    React.createElement("label", { className: SS.authInputTitle }, i18n.customersFirstName),
                    React.createElement("input", { className: SS.authInput + " textInput", name: "firstName", type: "text", onInput: this.onChangeAction, defaultValue: filters === null || filters === void 0 ? void 0 : filters.firstName, placeholder: i18n.customersFirstName })),
                React.createElement("div", { className: SS.authInputWrapper + " " + SS.small },
                    React.createElement("label", { className: SS.authInputTitle }, i18n.customersLastName),
                    React.createElement("input", { className: SS.authInput + " textInput", name: "lastName", type: "text", onInput: this.onChangeAction, defaultValue: filters === null || filters === void 0 ? void 0 : filters.lastName, placeholder: i18n.customersLastName })),
                React.createElement("div", { className: SS.authInputWrapper + " " + SS.small },
                    React.createElement("label", { className: SS.authInputTitle }, i18n.customersEmail),
                    React.createElement("input", { className: SS.authInput + " textInput", name: "email", type: "email", onInput: this.onChangeAction, defaultValue: filters === null || filters === void 0 ? void 0 : filters.email, placeholder: i18n.customersEmail })),
                React.createElement(Dropdown, { maxHeight: 190, isFixed: true, title: i18n.customersStatus, className: "modalDropDown " + S.small + " " + S.addInputWrapper + " " + S.dropdown, boxClass: "modalDropDownBox", label: (filters === null || filters === void 0 ? void 0 : filters.status) ? i18n.customersStatusItem(filters.status) : i18n.all },
                    React.createElement(Dropdown.List, null,
                        React.createElement(Dropdown.Item, { data: "categoryItem", autoClose: true, onClick: function () { return _this.onChangeDropdown(); } }, i18n.all),
                        React.createElement(Dropdown.Item, { data: "categoryItem", autoClose: true, onClick: function () { return _this.onChangeDropdown(CustomerStatus.approved); } }, i18n.customersStatusItem(CustomerStatus.approved)),
                        React.createElement(Dropdown.Item, { data: "categoryItem", autoClose: true, onClick: function () { return _this.onChangeDropdown(CustomerStatus.waiting); } }, i18n.customersStatusItem(CustomerStatus.waiting)),
                        React.createElement(Dropdown.Item, { data: "categoryItem", autoClose: true, onClick: function () { return _this.onChangeDropdown(CustomerStatus.pending); } }, i18n.customersStatusItem(CustomerStatus.pending)),
                        React.createElement(Dropdown.Item, { data: "categoryItem", autoClose: true, onClick: function () { return _this.onChangeDropdown(CustomerStatus.rejected); } }, i18n.customersStatusItem(CustomerStatus.rejected)),
                        React.createElement(Dropdown.Item, { data: "categoryItem", autoClose: true, onClick: function () { return _this.onChangeDropdown(CustomerStatus.unverified); } }, i18n.customersStatusItem(CustomerStatus.unverified))))),
            React.createElement("div", { className: S.customersMainBox }, loading ?
                React.createElement(SpinnerBlock, null)
                :
                    React.createElement(React.Fragment, null,
                        React.createElement("div", { className: S.customersList }, customers.map(function (customer, i) { return (React.createElement(CustomerItem, { key: i, customer: customer, isUpdated: _this.onUpdate })); })))),
            this.state.showCreateModal &&
                React.createElement(AddCustomerModal, { onClose: this.onCloseAdd, isUpdated: this.isUpdatedAdd })));
    };
    CustomersView = __decorate([
        inject('store'),
        observer
    ], CustomersView);
    return CustomersView;
}(React.Component));
export { CustomersView };
