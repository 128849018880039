import { CustomerStatus } from '../../models/Customer';
import { DocumentStatus } from '../../models/Document';
var shortWeekDayNames = ['Z', 'M', 'D', 'W', 'D', 'V', 'Z'];
var mediumWeekDayNames = ['Zo.', 'Ma.', 'Di.', 'Woe.', 'Do.', 'Vr.', 'Zat.'];
var longWeekDayNames = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'];
var longMonthNames = ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'October', 'November', 'December'];
var shortMonthNames = ['Jan', 'Feb', 'mrt', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sept', 'Okt', 'Nov', 'Dec'];
var pickerDayList = ['MA', 'DI', 'WO', 'DO', 'VR', 'ZA', 'ZO'];
var data = {
    locale: 'nl',
    otherLocale: 'en',
    otherLocaleLong: 'English',
    pick: (function (choices) { return choices['nl'] || choices['en'] || choices[Object.keys(choices)[0]]; }),
    pickWithLocale: (function (choices, locale) { return choices[locale] || choices[Object.keys(choices)[0]]; }),
    shortWeekDayNames: shortWeekDayNames,
    mediumWeekDayNames: mediumWeekDayNames,
    longWeekDayNames: longWeekDayNames,
    longMonthNames: longMonthNames,
    shortMonthNames: shortMonthNames,
    pickerDayList: pickerDayList,
    formatShortMonthNames: function (d) { return "" + shortMonthNames[d.getMonth()]; },
    formatMediumDayName: function (d) { return "" + mediumWeekDayNames[d.getDay()]; },
    formatShortDay: function (d) { return mediumWeekDayNames[d.getDay()] + " " + d.getDate(); },
    formatMediumDate: function (d) { return mediumWeekDayNames[d.getDay()] + " " + d.getDate() + " " + shortMonthNames[d.getMonth()] + " " + d.getFullYear(); },
    formatLongDate: function (d) { return longWeekDayNames[d.getDay()] + " " + d.getDate() + " " + longMonthNames[d.getMonth()]; },
    formatNumericalYearMonthDate: function (d) { return d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate(); },
    formatLongMonthYear: function (d) { return longMonthNames[d.getMonth()] + " " + d.getFullYear(); },
    formatLongDayName: function (d) { return "" + longWeekDayNames[d.getDay()]; },
    formatDateRange: function (d1, d2) { return ((d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear()) ?
        mediumWeekDayNames[d1.getDay()] + " " + d1.getDate() + " - " + mediumWeekDayNames[d2.getDay()] + " " + d2.getDate() + " " + shortMonthNames[d2.getMonth()] + " " + d2.getFullYear()
        : (d1.getFullYear() === d2.getFullYear()) ?
            mediumWeekDayNames[d1.getDay()] + " " + d1.getDate() + " " + shortMonthNames[d1.getMonth()] + " - " + mediumWeekDayNames[d2.getDay()] + " " + d2.getDate() + " " + shortMonthNames[d2.getMonth()] + " " + d2.getFullYear()
            :
                mediumWeekDayNames[d1.getDay()] + " " + d1.getDate() + " " + shortMonthNames[d1.getMonth()] + " " + d1.getFullYear() + " - " + mediumWeekDayNames[d2.getDay()] + " " + d2.getDate() + " " + shortMonthNames[d2.getMonth()] + " " + d2.getFullYear()); },
    // formatTime: (d) => `${(d.getHours()) % 12}:${d.getMinutes().toString().length < 2 ? d.getMinutes().toString() + '0' : d.getMinutes().toString()} ${d.getHours() >= 12 ? 'PM' : 'AM'}`,
    formatTime: function (d) { return d.getHours() + ":" + (d.getMinutes().toString().length < 2 ? '0' + d.getMinutes().toString() : d.getMinutes().toString()); },
    authHome: 'Home',
    authLinksSignIn: 'Log in',
    authLinksForgotPassword: 'Wachtwoord vergeten?',
    authAlreadyHaveAccount: 'Ik heb een account',
    authCreateCompany: 'Maak een Nieuw Bedrijf',
    authAlreadyCompany: 'Bedrijf bestaat al',
    authAlreadyCompanyJoin: 'Word lid van mijn bedrijf met een code.',
    authAlreadyCompanyEnterCode: 'Voer de uitnodigingscode in die je per e-mail of sms hebt ontvangen.',
    authLogin: 'Log in',
    authDownloadApp: 'Log in met de app of download deze van de App Store of Google Play.',
    authExistingAccountTitle: 'Bestaande gebruiker',
    authExistingAccount: 'Er is al een account gekoppeld aan dit e-mailadres of mobiele telefoonnummer.',
    authYouWillJoin: 'Je gaat meedoen',
    authJoin: 'Doe mee',
    authSignYouIn: 'Aanmelden',
    authNewOnSlot: 'Ben je nieuw bij Slot Letselschade?',
    authWelcomeToGivo: 'Welkom bij Slot Letselschade',
    authOr: 'of',
    authRegister: 'Maak een account',
    authDiscover: 'Ontdek Slot Letselschade',
    authLoginWithFacebook: 'Login met Facebook',
    authRegisterWithFacebook: 'Registreren met Facebook',
    avatarAlt: 'Avatar',
    avatarTitle: function (name) { return name; },
    alertModalTitle: 'Bericht',
    alertModalOk: 'Oke',
    alertModalConfirm: 'Bevestig',
    alertModalCancel: 'Annuleren',
    alertModalApply: 'Toepassen op',
    alertModalShowLess: 'Laat minder zien',
    alertDeleteDocumentSummary: 'Weet u zeker dat u deze PDF wilt verwijderen?',
    validationEmailError: 'Vul alstublieft uw e-mail adres in.',
    validationEmailValidError: 'Vul alstublieft een geldig e-mail adres in.',
    validationPhoneValidError: 'Vul alstublieft een geldig telefoonnummer in.',
    validationPasswordError: 'Vul alstublieft uw wachtwoord in.',
    validationCodeError: 'Vul alstublieft de validatiecode in die u via e-mail of sms heeft ontvangen.',
    validationPasswordLengthError: 'Uw nieuwe wachtwoord moet minimaal 6 tekens bevatten.',
    validationPasswordConfirmError: 'Uw wachtwoorden komen niet overeen.',
    validationFirstNameError: 'Vul alstublieft uw voornaam in.',
    validationLastNameError: 'Vul alstublieft uw achternaam in.',
    validationCompanyNameError: 'Vul alstublieft de naam van uw bedrijf in.',
    validationPositionNameError: 'U moet een naam voor deze positie invoeren.',
    validationLocationNameError: 'Vul alstublieft de naam in.',
    validationLocationStreetError: 'Vul alstublieft de straat in.',
    validationLocationCityError: 'Vul alstublieft de stad in.',
    validationSelectLocationError: 'Selecteer een adres uit uw zoekresultaat om een nieuwe locatie toe te voegen.',
    validationTeamListError: 'Selecteer ten minste één team uit de bovenstaande lijst.',
    validationTeamNameError: 'Vul alstublieft een naam voor dit team in.',
    validationCompanyCode: 'De uitnodigingscode moet 6 tekens lang zijn.',
    validationEventRecurrence: 'De verlengingsdatum moet later zijn dan de huidige vervaldatum.',
    validationHourlyWageError: 'Het uurloon moet geldig zijn.',
    validationMaxHoursError: 'Max. wekelijkse uren moet geldig zijn.',
    validationIndustryType: 'Vul alstublieft het type van uw branche in.',
    validationCompanyType: 'Vul alstublieft het type van uw branche in.',
    validationCodeInvalid: 'De validatiecode is verlopen of ongeldig. Vraag een nieuwe code aan.',
    validationTitleError: 'Voer de titel in.',
    validationContentError: 'Voer de inhoud in.',
    validationAboutError: 'Voer de over tekst in',
    validationAddressError: 'Voer het adres in',
    validationLatitudeError: 'Voer de breedtegraad in',
    validationLongitudeError: 'Voer de lengtegraad in',
    validationOpeningsError: 'Voer de beschikbaarheid in',
    validationCreateEmailError: 'Voer een geldig e-mailadres in.',
    validationCreatePasswordError: 'Voer een wachtwoord in.',
    validationCreateFirstNameError: 'Voer een voornaam in.',
    validationCreateLastNameError: 'Voer een achternaam in',
    validationCreatePhoneNumberError: 'Voer een telefoonnummer in',
    headerBarLogOut: 'Uitloggen',
    sideBarTitle: 'Slot CMS',
    sideBarWelcome: 'Welkom,',
    sideBarProfile: 'Mijn profiel',
    sideBarDashboard: 'Dashboard',
    sideBarSchedule: 'Schema',
    sideBarEmployees: 'Werknemers',
    sideBarSettings: 'Instellingen',
    sideBarHelp: 'Help',
    sideBarCopyright: "\u00A9 Slot, " + new Date().getFullYear(),
    sideBarQuickSetup: 'Snelle installatie',
    sideBarSubscription: 'Abonnement',
    sideBarCategories: 'Categorieën',
    sideBarSubCategories: 'Subcategorieën',
    sideBarAdmins: 'Beheerders',
    sideBarDocuments: 'Documenten',
    sideBarClaims: 'Claims',
    sideBarCustomers: 'Klanten',
    sideBarPushNotifications: 'Pushmeldingen',
    sideBarFaq: 'FAQ',
    sideBarFeed: 'RSS',
    loginViewEmailPlaceholder: 'E-mailadres',
    loginViewPasswordPlaceholder: 'Wachtwoord',
    loginViewRememberMe: 'Onthoud mij?',
    loginViewSignIn: 'Inloggen',
    loginViewNewUser: 'Nieuwe gebruiker',
    loginViewEmployeeAlert: 'Dit consolepaneel is alleen beschikbaar voor beheerders.',
    loginByContinuingYouAccept: 'Door uw account aan te maken, accepteert u ',
    loginViewTermsAndConditions: 'Slot\'s Algemene voorwaarden.',
    loginErrorLogin: 'Deze combinatie van e-mail en wachtwoord komt niet overeen met een bestaand account.',
    loginErrorLocked: 'Het account is vergrendeld. Probeer het over 10 minuten opnieuw.',
    loginLogoutMember: 'Terug naar inloggen',
    dashboardTitle: 'Dashboard',
    dashboardPhone: 'Telefoon',
    dashboardAbout: 'Over',
    dashboardAddress: 'Adres',
    dashboardLongitude: 'Longitude',
    dashboardLatitude: 'Latitude',
    dashboardOpenings: 'Wij zijn bereikbaar',
    dashboardConfig: 'Configuratie',
    dashboardPdfList: 'PDF-lijst',
    actionYes: 'Ja',
    actionNo: 'Nee',
    actionDelete: 'Verwijderen',
    actionCreate: 'Aanmaken',
    actionUpdate: 'Aanpassen',
    actionSend: 'Sturen',
    actionRefresh: 'Verversen',
    actionApprove: 'Bevestigen',
    actionReject: 'Afwijzen',
    settingsTitle: 'Instellingen',
    settingsEdit: 'Bewerk',
    settingsSave: 'Opslaan',
    homeSearch: 'Zoeken',
    homeSearchFor: function (type) { return "Voer de naam in van de " + type; },
    homeSearchForOffer: 'Zoek een aanbieding',
    homeSearchLocation: 'Selecteer een locatie',
    homeLatestOffers: 'Laatste aanbiedingen',
    homeFooterRights: 'slotletselschade.nl - Alle rechten voorbehouden',
    homeInitiative: 'Initiatief van',
    homeSupport: 'Supported by',
    adminsTitle: 'Beheerders',
    adminsDeleteUserSummary: 'Weet u zeker dat u deze admin wilt verwijderen?',
    adminsEmail: 'E-mailadres',
    adminsFirstName: 'Voornaam',
    adminsLastName: 'Achternaam',
    adminsFirstNamePlaceholder: 'Voornaam (optioneel)',
    adminsLastNamePlaceholder: 'Achternaam (optioneel)',
    adminsAction: 'Actie',
    adminsCreateTitle: 'Maak een nieuwe admin',
    claimsTitle: 'Claims',
    customersTitle: 'Klanten',
    customersEmail: 'E-mailadres',
    customersFirstName: 'Voornaam',
    customersLastName: 'Achternaam',
    customersName: 'Naam',
    customersStatus: 'Staat',
    customersStatusItem: function (status) {
        if (status === CustomerStatus.approved) {
            return 'Goedgekeurd';
        }
        else if (status === CustomerStatus.rejected) {
            return 'Afgewezen';
        }
        else if (status === CustomerStatus.unverified) {
            return 'Niet geverifieerd';
        }
        else if (status === CustomerStatus.waiting) {
            return 'Wachten';
        }
        else {
            return 'In behandeling';
        }
    },
    customersActions: 'Actie',
    customerApproveSummary: 'Weet u zeker dat u deze klant wilt goedkeuren?',
    customerRejectSummary: 'Weet u zeker dat u deze klant wilt afwijzen?',
    customersCreateTitle: 'Nieuwe klant aanmaken',
    customerTypeReferrer: 'Verwijzer',
    customerTypeCustomer: 'Klant',
    customerType: 'Type',
    customersViewProfile: 'Bekijk profiel',
    customersFilters: 'Filteren',
    pushNotificationsTitle: 'Pushmeldingen',
    pushNotificationSendTitle: 'Pushbericht verzenden',
    pushNotificationsConfirm: 'Weet u zeker dat u dit pushbericht naar alle gebruikers wilt sturen?',
    faqTitle: 'FAQ',
    faqFormTitle: 'Titel',
    faqFormContent: 'Inhoud',
    faqCreate: 'Maak een FAQ',
    faqUpdate: 'Verander FAQ',
    faqDeleteSummary: 'Weet u zeker dat u dit item wilt verwijderen?',
    faqCategory: 'Categorie',
    faqCategoryFaq: 'Faq',
    faqCategoryClaim: 'Claim',
    feedTitle: 'RSS',
    inputUploaderPlaceholder: 'Drag or click to upload a file',
    documentsTitle: 'Documenten',
    documentsApproveInfo: 'Weet u zeker dat u dit document wilt goedkeuren?',
    documentsRejectInfo: 'Weet u zeker dat u dit document wilt afwijzen?',
    documentsStatusItem: function (status) {
        if (status === DocumentStatus.approved) {
            return 'Goedgekeurd';
        }
        else if (status === DocumentStatus.rejected) {
            return 'Afgewezen';
        }
        else {
            return 'In behandeling';
        }
    },
    documentsStatusExpired: 'Verlopen',
    select: 'Selecteren',
    all: 'Alle',
};
export default data;
