import { del, EndPoints, get, post } from '../';
export function list(showAll, filters) {
    if (showAll === void 0) { showAll = false; }
    var data = {};
    if (showAll) {
        data.all = 1;
    }
    if ((filters === null || filters === void 0 ? void 0 : filters.firstName) && (filters === null || filters === void 0 ? void 0 : filters.firstName) !== '') {
        data.firstName = filters.firstName;
    }
    if ((filters === null || filters === void 0 ? void 0 : filters.lastName) && (filters === null || filters === void 0 ? void 0 : filters.lastName) !== '') {
        data.lastName = filters.lastName;
    }
    if ((filters === null || filters === void 0 ? void 0 : filters.email) && (filters === null || filters === void 0 ? void 0 : filters.email) !== '') {
        data.email = filters.email;
    }
    if ((filters === null || filters === void 0 ? void 0 : filters.status) && (filters === null || filters === void 0 ? void 0 : filters.status) !== '') {
        data.status = filters.status;
    }
    return get(EndPoints.customers, data);
}
export function detail(id) {
    var data = { id: id };
    return get(EndPoints.customersDetails, data);
}
export function createCustomer(request) {
    return post(EndPoints.customersCreate, request);
}
export function updateCustomer(request) {
    return post(EndPoints.customersUpdate, request);
}
export function deleteCustomer(request) {
    return del(EndPoints.customersDelete, request);
}
