import { __decorate, __extends } from "tslib";
import { inject, observer } from 'mobx-react';
import React from 'react';
import { deleteCustomer, updateCustomer } from '../api/customers';
import { customConfirm } from '../utils/utils';
import { BASE_DOCUMENT } from '../api';
import { deleteDocument, list, updateDocument } from '../api/document';
import { FileIcon } from '../icons/FileIcon';
import { CustomerStatus } from '../models/Customer';
import { DocumentStatus } from '../models/Document';
import { Button } from './Button';
import S from './CustomerItem.styl';
import SS from './CustomerItem.styl';
import { pushHistory, Route } from "../router/actions";
var CustomerItem = /** @class */ (function (_super) {
    __extends(CustomerItem, _super);
    function CustomerItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.stateCount = 0;
        _this.state = {
            loading: false,
            documents: [],
        };
        _this.deleteItem = function () {
            var i18n = _this.props.store.i18n;
            var customer = _this.props.customer;
            if (customer) {
                customConfirm(i18n.adminsDeleteUserSummary, _this.props.store, function (success) {
                    if (success) {
                        _this.setState({ loading: true });
                        var request = {
                            id: customer.id,
                        };
                        deleteCustomer(request).then(function () {
                            _this.setState({ loading: false });
                            _this.isUpdated();
                        }).catch(function (error) {
                            _this.setState({ loading: false });
                            alert(error);
                        });
                    }
                });
            }
        };
        _this.approveItem = function () {
            var i18n = _this.props.store.i18n;
            var customer = _this.props.customer;
            if (customer) {
                customConfirm(i18n.customerApproveSummary, _this.props.store, function (success) {
                    if (success) {
                        _this.setState({ loading: true });
                        var request = {
                            id: customer.id,
                        };
                        updateCustomer({ customerId: customer.id, status: CustomerStatus.approved }).then(function () {
                            _this.setState({ loading: false });
                            _this.isUpdated();
                        }).catch(function (error) {
                            _this.setState({ loading: false });
                            alert(error);
                        });
                    }
                });
            }
        };
        _this.rejectItem = function () {
            var i18n = _this.props.store.i18n;
            var customer = _this.props.customer;
            if (customer) {
                customConfirm(i18n.customerRejectSummary, _this.props.store, function (success) {
                    if (success) {
                        _this.setState({ loading: true });
                        var request = {
                            id: customer.id,
                        };
                        updateCustomer({ customerId: customer.id, status: CustomerStatus.rejected }).then(function () {
                            _this.setState({ loading: false });
                            _this.isUpdated();
                        }).catch(function (error) {
                            _this.setState({ loading: false });
                            alert(error);
                        });
                    }
                });
            }
        };
        _this.updateDocument = function (documentId, status) {
            var i18n = _this.props.store.i18n;
            var customer = _this.props.customer;
            if (customer) {
                var title = status === DocumentStatus.approved ? i18n.documentsApproveInfo : i18n.documentsRejectInfo;
                customConfirm(title, _this.props.store, function (success) {
                    if (success) {
                        _this.setState({ loading: true });
                        var request = { customerId: customer.id, documentId: documentId, status: status };
                        updateDocument(request).then(function () {
                            _this.setState({ loading: false });
                            _this.fetchDocuments();
                        }).catch(function (error) {
                            _this.setState({ loading: false });
                            alert(error);
                        });
                    }
                });
            }
        };
        _this.deleteDocument = function (documentId) {
            var i18n = _this.props.store.i18n;
            var customer = _this.props.customer;
            if (customer) {
                customConfirm(i18n.alertDeleteDocumentSummary, _this.props.store, function (success) {
                    if (success) {
                        _this.setState({ loading: true });
                        var request = { documentId: documentId };
                        deleteDocument(request).then(function () {
                            _this.setState({ loading: false });
                            _this.fetchDocuments();
                        }).catch(function (error) {
                            _this.setState({ loading: false });
                            alert(error);
                        });
                    }
                });
            }
        };
        _this.isUpdated = function () {
            if (_this.props.isUpdated) {
                _this.props.isUpdated();
            }
        };
        _this.openDocument = function (document) {
            var url = BASE_DOCUMENT + document.fileName;
            window.open(url, '_blank');
        };
        _this.getDocumentStatus = function (document) {
            var i18n = _this.props.store.i18n;
            if (document.expiryDate) {
                return i18n.documentsStatusExpired;
            }
            else {
                return i18n.documentsStatusItem(document.status || DocumentStatus.pending);
            }
        };
        _this.getStatusClass = function (status) {
            if (status === CustomerStatus.approved) {
                return S.approved;
            }
            else if (status === CustomerStatus.rejected) {
                return S.rejected;
            }
            return S.pending;
        };
        _this.showCustomer = function () {
            var _a;
            pushHistory(_this.props.store, Route.CustomersDetails, { customerId: (_a = _this.props.customer) === null || _a === void 0 ? void 0 : _a.id });
        };
        return _this;
    }
    CustomerItem.prototype.componentDidMount = function () {
        var _a, _b;
        this.setState({ documents: ((_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.customer) === null || _b === void 0 ? void 0 : _b.documents) || [] });
    };
    CustomerItem.prototype.fetchDocuments = function () {
        var _this = this;
        var _a;
        list({ customerId: (_a = this.props.customer) === null || _a === void 0 ? void 0 : _a.id }).then(function (result) {
            _this.setState({ documents: result.items });
            _this.stateCount++;
        }).catch(function (error) {
            alert(error.toString());
            throw error;
        });
    };
    CustomerItem.prototype.render = function () {
        var _this = this;
        var props = this.props;
        var customer = props.customer;
        var i18n = props.store.i18n;
        var documents = this.state.documents;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: S.customerItem }, customer ?
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: S.customerIcon, onClick: function () { return _this.showCustomer(); } }, !customer.photo || customer.photo === '' ?
                        React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", enableBackground: "new 0 0 24 24", height: "120px", viewBox: "0 0 24 24", width: "120px", fill: "#e8eaed" },
                            React.createElement("g", null,
                                React.createElement("rect", { fill: "none", height: "24", width: "24" })),
                            React.createElement("g", null,
                                React.createElement("path", { d: "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 4c1.93 0 3.5 1.57 3.5 3.5S13.93 13 12 13s-3.5-1.57-3.5-3.5S10.07 6 12 6zm0 14c-2.03 0-4.43-.82-6.14-2.88C7.55 15.8 9.68 15 12 15s4.45.8 6.14 2.12C16.43 19.18 14.03 20 12 20z" })))
                        :
                            React.createElement("img", { src: customer.photo, alt: customer.photo })),
                    React.createElement("div", { className: S.contentBox },
                        React.createElement("div", { className: S.email },
                            i18n.customersEmail,
                            ": ",
                            customer.email),
                        React.createElement("div", { className: S.firstName },
                            i18n.customersName,
                            ": ",
                            customer.firstName,
                            " ",
                            customer.lastName),
                        React.createElement("div", { className: S.lastName },
                            i18n.customersStatus,
                            ":\u00A0",
                            React.createElement("div", { className: S.customerStatus + ' ' + this.getStatusClass(customer.status) }, i18n.customersStatusItem(customer.status || CustomerStatus.pending))),
                        React.createElement("div", { className: S.lastName + " " + S.link, onClick: function (_) { return _this.showCustomer(); } }, i18n.customersViewProfile),
                        React.createElement("div", { className: S.action }, customer.status === CustomerStatus.pending ?
                            React.createElement(React.Fragment, null,
                                React.createElement(Button, { className: S.approveButton, type: "button", onClick: this.approveItem }, i18n.actionApprove),
                                React.createElement(Button, { className: S.rejectButton, type: "button", onClick: this.rejectItem }, i18n.actionReject))
                            :
                                React.createElement(React.Fragment, null,
                                    React.createElement(Button, { className: S.deleteButton, type: "button", onClick: this.deleteItem }, i18n.actionDelete)))))
                :
                    React.createElement(React.Fragment, null)),
            (documents === null || documents === void 0 ? void 0 : documents.length) !== 0 ?
                React.createElement(React.Fragment, null,
                    React.createElement("span", { className: S.subTitle }, i18n.documentsTitle),
                    React.createElement("div", { className: S.documentList, key: this.stateCount }, documents === null || documents === void 0 ? void 0 : documents.map(function (document, i) { return (React.createElement("div", { className: S.documentItem, key: i },
                        React.createElement(FileIcon, { width: 48, height: 48 }),
                        React.createElement("div", { onClick: function (_) { return _this.openDocument(document); }, className: SS.documentName },
                            i18n.customersName,
                            ": ",
                            document.name,
                            React.createElement("br", null),
                            i18n.customersStatus,
                            ": (",
                            _this.getDocumentStatus(document),
                            ")"),
                        React.createElement("div", { className: S.action }, document.status === DocumentStatus.pending ?
                            React.createElement(React.Fragment, null,
                                React.createElement(Button, { className: S.approveButton, type: "button", onClick: function (_) { return _this.updateDocument(document.id, DocumentStatus.approved); } }, i18n.actionApprove),
                                React.createElement(Button, { className: S.rejectButton, type: "button", onClick: function (_) { return _this.updateDocument(document.id, DocumentStatus.rejected); } }, i18n.actionReject))
                            :
                                React.createElement(React.Fragment, null,
                                    React.createElement(Button, { className: S.deleteButton, type: "button", onClick: function (_) { return _this.deleteDocument(document.id); } }, i18n.actionDelete))))); })))
                :
                    React.createElement(React.Fragment, null)));
    };
    CustomerItem = __decorate([
        inject('store'),
        observer
    ], CustomerItem);
    return CustomerItem;
}(React.Component));
export { CustomerItem };
